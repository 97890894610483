import {
  SET_USER,
} from '../actions/types';

const INITIAL_USER = {
  profile: JSON.parse(localStorage.getItem('user')),
  loading: true,
};

const userReducer = (state = INITIAL_USER, action) => {
    // Check to see if the reducer cares about this action
  switch (action.type) {
    case SET_USER:
      console.log("SET USER - Start",JSON.stringify(state))
      state = Object.assign({}, state, {
        profile: action.payload,
        loading: false,
      });
      console.log("SET USER - End",JSON.stringify(state))
      //localStorage.setItem('user_redux',JSON.stringify(state));
      return state;

    default:
      return state;
  }
};

export default userReducer;
