import mock from "../mock"

export const searchResult = [
  {
    id: 1,
    target: "AnalyticsDashboard",
    title: "Dashboard",
    link: "/",
    icon: "Home",
    starred: false
  },
  {
    id: 3,
    target: "Manage_school",
    title: "Manage School",
    link: "/school-profile",
    icon: "Globe",
    starred: false
  },
  {
    id: 4,
    target: "Manage_students",
    title: "Manage Students",
    link: "/manage-students",
    icon: "Users",
    starred: false
  },
  {
    id: 5,
    target: "View_classes",
    title: "View Classes",
    link: "/manage-classes",
    icon: "Clock",
    starred: false
  },
  {
    id: 6,
    target: "Approval_classes",
    title: "Approval Classes",
    link: "/manage-classes/approval-classes",
    icon: "Clock",
    starred: false
  },
  {
    id: 7,
    target: "Completed_classes",
    title: "Completed Classes",
    link: "/manage-classes/completed-classes",
    icon: "Clock",
    starred: false
  },
  {
    id: 8,
    target: "Ongoing_classes",
    title: "Ongoing Classes",
    link: "/manage-classes/ongoing-classes",
    icon: "Clock",
    starred: false
  },
  {
    id: 9,
    target: "All_topics",
    title: "All Topics",
    link: "/manage-topics",
    icon: "Server",
    starred: false
  },{
    id: 10,
    target: "notifications",
    title: "Notifications",
    link: "/notifications",
    icon: "Bell",
    starred: false
  }
  
]

mock.onGet("/api/search/bookmarks/data").reply(200, {
  searchResult
})

mock.onPost("/api/update/bookmarks").reply(request => {
  const bookmarkToUpdate = JSON.parse(request.data).obj

  searchResult.filter(i => {
    if (i.id === bookmarkToUpdate.id) {
      return (i.starred = !bookmarkToUpdate.starred)
    } else {
      return null
    }
  })
  return [200]
})
