import mock from "../mock"

export const searchResult = [
  {
    groupTitle: "Pages",
    searchLimit: 4,
    data: [
      {
        id: 1,
        target: "AnalyticsDashboard",
        title: "Dashboard",
        link: "/",
        icon: "Home"
      },{
    id: 2,
    target: "Chat",
    title: "Chat",
    link: "/chat",
    icon: "MessageSquare"
  },
  {
    id: 3,
    target: "Manage_school",
    title: "Manage School",
    link: "/school-admin/school/uni",
    icon: "Globe"
  },
  {
    id: 4,
    target: "Manage_students",
    title: "Manage Students",
    link: "/school-admin/students",
    icon: "Users"
  },
  {
    id: 5,
    target: "View_classes",
    title: "View Classes",
    link: "/school-admin/classes/classes",
    icon: "Clock"
  },
  {
    id: 6,
    target: "Approval_classes",
    title: "Approval Classes",
    link: "/school-admin/classes/app-classes",
    icon: "Clock"
  },
  {
    id: 7,
    target: "Completed_classes",
    title: "Completed Classes",
    link: "/school-admin/classes/com-class",
    icon: "Clock"
  },
  {
    id: 8,
    target: "Ongoing_classes",
    title: "Ongoing Classes",
    link: "/school-admin/classes/ongo-class",
    icon: "Clock"
  },
  {
    id: 9,
    target: "All_topics",
    title: "All Topics",
    link: "/school-admin/topics/topics",
    icon: "Server"
  },{
    id: 10,
    target: "notifications",
    title: "Notifications",
    link: "/school-admin/notifications",
    icon: "Bell"
  }
    ]
  },
  {
    groupTitle: "Files",
    searchLimit: 4,
    data: [
      {
        title: "Passport Image",
        by: "Oliver Queen",
        size: "52kb",
        file: require("../../assets/img/icons/jpg.png")
      },
      {
        title: "Parenting Guide",
        by: "Alfred Pennyworth",
        size: "2.3mb",
        file: require("../../assets/img/icons/doc.png")
      },
      {
        title: "Class Notes",
        by: "Barry Allen",
        size: "30kb",
        file: require("../../assets/img/icons/doc.png")
      },
      {
        title: "Class Attendance",
        by: "Walter White",
        size: "52mb",
        file: require("../../assets/img/icons/xls.png")
      }
    ]
  },
  {
    groupTitle: "Contacts",
    searchLimit: 4,
    data: [
      {
        title: "Mia Davis",
        email: "miadavis@teleworm.us",
        img: require("../../assets/img/portrait/small/avatar-s-8.jpg"),
        date: "01/03/2020"
      },
      {
        title: "Norris Carrière",
        email: "NorrisCarriere@rhyta.com",
        img: require("../../assets/img/portrait/small/avatar-s-3.jpg"),
        date: "07/03/2020"
      },
      {
        title: "Charlotte Gordon",
        email: "CharlotteGordon@jourrapide.com",
        img: require("../../assets/img/portrait/small/avatar-s-26.jpg"),
        date: "14/03/2020"
      },
      {
        title: "Robert Nash",
        email: "RobertNash@dayrep.com",
        img: require("../../assets/img/portrait/small/avatar-s-25.jpg"),
        date: "21/03/2020"
      }
    ]
  }
]

mock.onGet("/api/main-search/data").reply(200, {
  searchResult
})
