import React from "react"
import './spiner.css'
class SpinnerComponent extends React.Component {
  render() {
    return (
      <div className="fallback-spinner vh-100">
      <div className='loading-container-wrap'><div className="lds-dual-ring"></div></div>
      </div>
    )
  }
}

export default SpinnerComponent
